<template>
  <div class="container">
    <div class="export">
      <div class="input">
        <div>关键词：</div>
        <el-input v-model="stockInput" clearable placeholder="商品条码/商品名称"></el-input>
      </div>
      <div class="brand">
        库存筛选：
        <el-select v-model="stock_con" clearable placeholder="请选择">
          <el-option label="大于" value="1"></el-option>
          <el-option label="小于" value="2"></el-option>
          <el-option label="等于" value="3"></el-option>
          <el-option label="不等于" value="4"></el-option>
        </el-select>
      </div>
      <div class="input" style="width:150px;margin-left: 10px;">
        <el-input v-model="stock_num" placeholder="输入数量"></el-input>
      </div>
      <el-button type="primary" @click="getStockList('nopage')">查询</el-button>
      <el-button type="info" @click="stockExport">导出</el-button>


    </div>
    <div class="export" style="margin-top: 20px;">
      <div class="msg">
        <div>实际可用库存数=(库存数-待发货量-待支付量)</div>
        <div>实际可用库存小于14日销量，记录变红，库存预警</div>
        <!-- <div>滞销量：采购商品后按平均3个月内售完计算，当月未达到的数量计入滞销量
          <el-popover placement="right" width="200" trigger="hover">
                        <div>例：采购90包货品，按3个月销完，月均30包，每月未达到此数量的将统计在滞销量内，下月依次累加</div>
                        <el-button slot="reference" style="border: 0; padding: 0;margin-left: 10px;">
                            <i class="el-icon-question"></i>
                        </el-button>
                    </el-popover>
        </div> -->

      </div>
    </div>

    <div class="table">
      <el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{ textAlign: 'center' }" border
        :data="stockList" @selection-change="handleSelectionChange" :row-class-name="tableRowClassName"
        style="width: 100%">
        <el-table-column type="selection" width="60"></el-table-column>
        <el-table-column type="index" label="编号" width="60"></el-table-column>
        <!-- <el-table-column prop="item_id" label="商品ID"></el-table-column> -->
        <el-table-column prop="item_name" width="150" label="商品名称"></el-table-column>
        <el-table-column width="120" prop="item_code" label="商品条码"></el-table-column>
        <el-table-column width="120" prop="single" label="规格"></el-table-column>
        <el-table-column width="150" prop="stock" sortable label="库存数"></el-table-column>
        <el-table-column width="150" prop="real_stock" label="实际可用库存数"></el-table-column>
        <el-table-column width="130" prop="waiting_pay_quantity" sortable label="待支付量"></el-table-column>
        <el-table-column width="130" prop="waiting_quantity" sortable label="待发货量"></el-table-column>
        <el-table-column width="130" prop="issued_quantity" sortable label="已发货量"></el-table-column>
        <!-- <el-table-column width="120" prop="increment_num"  label="滞销量">
          <template slot-scope="scope">
            <div :class="scope.row.increment_num > 0? 'red': ''">{{ scope.row.increment_num || '-' }}</div>
          </template>
        </el-table-column>
        <el-table-column width="120" prop="unsalable_value"  label="滞销状态">
          <template slot-scope="scope">
            <div :class="scope.row.increment_num > 0? 'red': ''">{{ scope.row.unsalable_value || '-' }}</div>
          </template>
        </el-table-column> -->
        <el-table-column width="130" prop="sale_day" sortable label="今日销量"></el-table-column>
        <el-table-column width="130" prop="sale_7_day" sortable label="7日销量"></el-table-column>
        <el-table-column width="130" prop="sale_14_day" sortable label="14日销量"></el-table-column>
        <el-table-column width="130" prop="sale_30_day" sortable label="30日销量"></el-table-column>
        <!-- <el-table-column prop="hair_volume_quantity" sortable label="可发货量"></el-table-column> -->
        <el-table-column label="详情" fixed="right">
          <template slot-scope="scope">
            <el-button @click="getStockUpdateList(scope.row)" type="text" size="small">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background :hide-on-single-page="false" @current-change="curretnStockChange"
        @prev-click="curretnStockChange" @size-change="handleSizeChange" @next-click="curretnStockChange"
        :current-page="stockPage" :page-sizes="[10, 20, 30, 40]" layout="total,sizes,prev, pager, next,jumper"
        :total="stockTotal"></el-pagination>
    </div>
    <el-dialog title="库存更新记录" :show-close="true" :close-on-press-escape="false" :close-on-click-modal="false"
      :visible.sync="dialogVisible" width="90%">
      <span>
        <div class="export">
          <div class="date">
            日期：
            <el-date-picker v-model="value1" type="datetimerange" :default-time="['00:00:00', '23:59:59']"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </div>
          <!-- <div class="input">
            <div>关键词：</div>
            <el-input v-model="detailInput" placeholder="商品ID/商品条码/商品名称"></el-input>
          </div>-->
          <div class="logistics">
            类型：
            <el-select v-model="typeValue" clearable placeholder="请选择">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
          <el-button type="primary" @click="getStockUpdateList('nopage')">查询</el-button>
        </div>
        <el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{ textAlign: 'center' }" :data="updateList"
          border style="width: 100%; margin-top: 20px">
          <el-table-column type="index" label="编号"></el-table-column>
          <el-table-column prop="type_name" label="类型"></el-table-column>
          <el-table-column prop="source_no" label="单号"></el-table-column>
          <!-- <el-table-column prop="item_id" label="商品ID"></el-table-column> -->
          <el-table-column prop="item_name" label="商品名称"></el-table-column>
          <el-table-column prop="item_code" label="商品条码"></el-table-column>
          <el-table-column prop="single" label="规格"></el-table-column>
          <el-table-column prop="upd_num" label="更新数量"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
          <el-table-column prop="create_date" label="更新时间"></el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination background :current-page="updatePage" @current-change="curretnDetailChange"
            @prev-click="curretnDetailChange" @next-click="curretnDetailChange" layout="total,prev, pager, next"
            :total="detailTotal" :hide-on-single-page="false"></el-pagination>
        </div>
      </span>
      <span slot="footer" class="dialog-header">
        <!-- <el-button @click="closeDetailVisible">关闭</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { formatDateTime, urlEncode } from '@/utils/util.js'
import { BASE_URL, BASE_URL2 } from '../request/request.config';
export default {
  name: 'stock',
  data() {
    return {
      dialogVisible: false,
      stockInput: '',
      limit: 10,
      updatePage: 1,
      BASE_URL2,
      stockPage: 1,
      detailInput: '',
      typeOptions: [
        {
          value: 1,
          label: '订单'
        },
        {
          value: 2,
          label: '采购'
        },
        {
          value: 3,
          label: '售后'
        }
      ],
      typeValue: '',
      stockTotal: 0,
      detailTotal: 0,
      detailLimit: 10,
      order_by: '',
      currentStockDetail: {},
      value1: [],
      stockList: [],
      stock_con: '',
      stock_num: '',
      multipleSelection: [],
      updateList: []
    }
  },
  computed: {
    shopInfo() {
      return JSON.parse(window.localStorage.getItem('shopInfo')) || {}
    }
  },
  created() {
    this.getStockList()
  },
  watch: {

    dialogVisible(newName, oldName) {
      if (newName === false) {
        this.detailInput = ''
        this.updatePage = 1
        this.value1 = []
        this.typeValue = ''
        this.currentStockDetail = {}
      }
    }
  },
  methods: {
    toStockBookList() {
      this.$router.push('/book')
    },
    handleSelectionChange(val) {
      // 多选值改变
      this.multipleSelection = val
    },
    tableRowClassName({ row, rowIndex }) {
      // eslint-disable-next-line eqeqeq
      if (row.real_stock < row.sale_14_day) {
        return 'bgcred'
      }
      return ''
    },
    closeDetailVisible() {
      this.dialogVisible = false
      this.detailInput = ''
      this.updatePage = 1
      this.value1 = []
      this.currentStockDetail = {}
    },
    curretnDetailChange(e) {
      // 切换页码
      this.updatePage = e
      this.getStockUpdateList()
    },
    sortChange(e) {
      var orderBy = e.prop
      if (e.order === 'descending') {
        this.order_by = orderBy + '__desc'
      } else {
        this.order_by = orderBy + '__asc'
      }
      this.getStockList()
    },
    curretnStockChange(e) {
      // 切换page
      this.stockPage = e
      this.getStockList()
    },
    handleSizeChange(e) {
      this.limit = e
      this.getStockList()
    },
    getStockUpdateList(e) {
      // 库存更新记录
      // eslint-disable-next-line eqeqeq
      this.updatePage = e === 'nopage' ? 1 : this.updatePage
      e = this.currentStockDetail.stock_id ? this.currentStockDetail : e
      this.currentStockDetail = e

      // eslint-disable-next-line camelcase
      var stock_id = e.stock_id
      var keyword = this.detailInput
      // eslint-disable-next-line camelcase
      var start_date = ''
      // eslint-disable-next-line camelcase
      var end_date = ''
      console.log(this.value1, 1)
      if (Object.prototype.toString.call(this.value1) === '[object Array]') {
        if (this.value1[0]) {
          // eslint-disable-next-line camelcase
          start_date = formatDateTime(this.value1[0])
        }
        if (this.value1[1]) {
          // eslint-disable-next-line camelcase
          end_date = formatDateTime(this.value1[1])
        }
      }
      // eslint-disable-next-line camelcase
      var log_type = this.typeValue
      var limit = this.detailLimit
      var page = this.updatePage
      this.dialogVisible = true
      this.$http2
        .post('/merapi/stock/upd_lst', {

          stock_id,
          start_date,
          end_date,
          log_type,
          page,
          keyword,
          limit
        })
        .then(res => {
          this.updateList = res.data.data.list
          this.detailTotal = res.data.data.total
        })
        .catch(err => {
          return this.$message.error(err)
        })
    },
    stockExport() {
      // 库存列表导出
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id
      // eslint-disable-next-line camelcase
      var order_by = this.order_by
      var keyword = this.stockInput
      var stock_con = this.stock_con
      var stock_num = this.stock_num
      var multipleSelection = this.multipleSelection
      // eslint-disable-next-line camelcase
      var ids = []
      if (multipleSelection.length > 0) {
        multipleSelection.forEach(ele => {
          ids.push(ele.stock_id)
        })
      }
      // eslint-disable-next-line camelcase
      ids = ids.join(',')
      var token = window.localStorage.getItem('token')
      try {
        let params = {
          shop_id, ids, keyword, order_by, token, stock_con, stock_num

        }
        let data = urlEncode(params)
        window.location.href = BASE_URL2 + '/merapi/stock/export?' + data
      } catch {
        this.$message.error('服务器繁忙，请稍后重试')
      }
      // this.$http2
      //   .get('/merapi/stock/export',{ params:{ shop_id, ids, keyword, order_by }})
      //   .then(res => {
      //     this.$message.success(res.data.msg)
      //   })
      //   .catch(err => {
      //     return this.$message.error(err)
      //   })
    },
    getStockList(e) {
      // 获取库存列表
      // eslint-disable-next-line camelcase
      var shop_id = this.shopInfo.shop_id
      var keyword = this.stockInput
      var stock_con = this.stock_con
      var stock_num = this.stock_num
      // eslint-disable-next-line camelcase
      // var order_by = this.order_by
      this.stockPage = e === 'nopage' ? 1 : this.stockPage
      var page = this.stockPage
      var limit = this.limit
      this.$http2
        .post('/merapi/stock/list', { shop_id, stock_con, stock_num, keyword, page, limit })
        .then(res => {
          this.stockList = res.data.data.list
          this.stockTotal = res.data.data.total
        })
        .catch(err => {
          return this.$message.error(err)
        })
    }
  }
}
</script>

<style scoped lang="less">
// .el-table/deep/.el-table__body tr:hover>td {
//   background-color: #eee!important;
// }
.red {
  color: red;
}

.el-table/deep/.el-table__body tr.bgcred>td {
  background-color: rgb(245, 108, 108) !important;
}

.brand {
  margin-left: 30px;
}

.export {
  display: flex;
  align-items: center;

  .input {
    font-size: 16px;

    color: #222;
  }

  .brand {
    font-size: 16px;

    color: #222;
  }

  .el-button {
    background-color: #0078fb;
  }

  .date {
    font-size: 18px;
    margin-right: 20px;
  }

  .input {
    display: flex;
    align-items: center;

    div {
      width: 100px;
      font-size: 16px;
    }

    .el-input {
      width: 300px;
      font-size: 14px;
    }
  }

  .el-button {
    margin-left: 40px;
  }

  .msg {
    // margin-left: 30px;
    font-size: 13px;
    color: #0078fb;
  }
}

.el-table /deep/ .bgcred {
  background-color: rgb(245, 108, 108);
  color: #fff;

}

.table {
  margin-top: 30px;
}

.logistics {
  margin-left: 20px;
  font-size: 18px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
  align-items: center;
}</style>
